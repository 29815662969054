<template>
  <div class="business">
    <div class="business__wrapper">
      <h2
        v-if="!hideTitle"
        class="business__title typography__headline2"
      >
        Используйте в&nbsp;любом&nbsp;бизнесе
      </h2>

      <ul class="business__list">
        <li
          v-for="(business, businessIndex) in businessList"
          :key="businessIndex"
          class="business__item"
        >
          {{ business }}
        </li>
      </ul>
    </div>

    <BaseModalButton
      v-if="!hideButton"
      class="base-button-big base-button-first business__button typography__title2"
      :modal="MODAL.AuthRegistration"
    >
      Стать клиентом Donut
    </BaseModalButton>
  </div>
</template>

<script setup lang="ts">
interface Props {
  businessList: string[];
  hideButton?: boolean;
  hideTitle?: boolean;
}

defineProps<Props>();

const { isModalOpen } = useModalStore();
</script>

<style scoped>
.business {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (--desktop) {
  .business {
    row-gap: 40px;
  }
}

.business__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

@media (--desktop) {
  .business__wrapper {
    row-gap: 16px;
  }
}

.business__title {
  max-width: 312px;
}

@media (--desktop) {
  .business__title {
    max-width: 780px;
    font-size: 60px;
    line-height: 1.1;
  }
}

.business__button {
  border-radius: 30px;
  padding: 12px 24px;
  max-width: max-content;
}

@media (--desktop) {
  .business__button {
    padding: 16px 40px;
  }
}

.business__list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 16px;
  max-width: 535px;
}

@media (--desktop) {
  .business__list {
    column-gap: 24px;
  }
}

.business__item {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--grey-2);
  color: var(--grey-2);
}

@media (--desktop) {
  .business__item {
    font-size: 16px;
  }
}

.business__item:last-child {
  border-bottom: 1px solid var(--orange-1);
  color: var(--orange-1);
}
</style>
